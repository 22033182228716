import { Chip } from "@mui/material";

function Chips({ limit, data }) {
    if (data && Array.isArray(data)) {
        return data.map(v => <Chip label={v.label ?? v} />);
    }
    if (data) {
        return [<Chip label={data} />];
    }
    return null;
}

export default Chips;