import config from "../../config";

export default {
    load: async (token, name, filter, skip, limit) => {
        let _filter = '';
        if (filter) {
            _filter = '?' + new URLSearchParams({ filter: JSON.stringify(filter) }).toString();
        }
        let response = await fetch(config.url + '/' + name  + _filter, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return {
                    status: 'err',
                    statusCode: res.status,
                    statusText: res.statusText
                }
            }
        }, err => {
            console.log('err');
            console.log(err);
        });
        return response;
    },
    aggregate: async (token, name, pipeline) => {
        let response = await fetch(config.url + '/' + name + '/aggregate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify({
                pipeline: pipeline
            })
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return {
                    status: 'err',
                    statusCode: res.status,
                    statusText: res.statusText
                }
            }
        }, err => {
            console.log('err');
            console.log(err);
        });
        return response;
    },
    add: async (token, name, data) => {
        let response = await fetch(config.url + '/' + name + '/one', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify({ item: data })
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return {
                    status: 'err',
                    statusCode: res.status,
                    statusText: res.statusText
                }
            }
        }, err => {
            console.log('err');
            console.log(err);
        });
        return response;
    },
    update: async(token, name, filter, update, options) => {
        let response = await fetch(config.url + '/' + name + '/one', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify({
                filter: filter,
                update: update,
                options: options
            })
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return {
                    status: 'err',
                    statusCode: res.status,
                    statusText: res.statusText
                }
            }
        }, err => {
            console.log('err');
            console.log(err);
        });
        return response;
    },
    updateDirect: async(token, name, id, document, options) => {
        delete document._id;
        let response = await fetch(config.url + '/' + name + '/' + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify({
                document: document,
                options: options
            })
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return {
                    status: 'err',
                    statusCode: res.status,
                    statusText: res.statusText
                }
            }
        }, err => {
            console.log('err');
            console.log(err);
        });
        return response;
    },
    delete: async (token, name, id) => {
        let response = await fetch(config.url + '/' + name + '/' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return {
                    status: 'err',
                    statusCode: res.status,
                    statusText: res.statusText
                }
            }
        }, err => {
            console.log('err');
            console.log(err);
        });
        return response;
    },
    loadFile: async (token, id) => {
        let response = await fetch(config.url + `/files/get/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        }).then(res => res.blob())
        .then(blb => new Promise((res, rej) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                res(reader.result); 
            }, false);
            reader.readAsDataURL(blb);
        }));
        return response;
    },
    users: async (token) => {
        let response = await fetch(config.url + '/system/book/users', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            }
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return {
                    status: 'err',
                    statusCode: res.status,
                    statusText: res.statusText
                }
            }
        }, err => {
            console.log('err');
            console.log(err);
        });
        return response;
    }
}