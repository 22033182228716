import styled from '@emotion/styled';
import { Alert, Autocomplete, Box, Button, ButtonGroup, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, Menu, Switch, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useAuth } from '../../../providers/AuthProvider';
import { useBook } from '../../../providers/BookProvider';
import books from '../../../shared/books';
import item from '../../../shared/item';
import _protected from '../../../shared/protected';
import AdminCatalog from '../../private/page/AdminCatalog';
import LazyImg from '../../public/LazyImg';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Chips from '../../public/Chips';
import LicItem from '../../public/Item';

function RoomSku(props) {
  console.log('rerender RoomSku');
  let auth = useAuth();
  let books = useBook();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const [deleting, setDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const [currentTask, setCurrentTaskId] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [linked, setLinked] = useState([]);

  const [selected, setSelected] = useState([]);

  const [formAlert, setFormAlert] = useState("");
  const [formError, setFormError] = useState({});

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({});

  const columns = [{
    field: 'ID',
    headerName: 'Номер (ID)',
    width: 50
  }, {
    field: 'actions',
    type: 'actions',
    headerName: 'Edit',
    width: 60,
    cellClassName: 'actions',
    getActions: ({ id }) => {
      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => handleEditClick(id)}
          color="inherit"
        />
      ];
    }
  }, {
    field: '_stage',
    headerName: 'Статус'
  }, {
    field: 'link',
    headerName: 'Ссылка на задачу',
    filterable: false
  }, {
    field: 'contract',
    headerName: 'Номер Договора',
    width: 400
  }, {
    field: 'contract_date',
    headerName: 'Дата договора',
    width: 300
  }, {
    field: 'end_contract',
    headerName: 'Срок действия',
    width: 300
  }, {
    field: 'title_project',
    headerName: 'Наименование проекта',
    width: 300
  }, {
    field: 'comment',
    headerName: 'Комментарий',
    width: 300
  }, {
    field: 'countSku',
    headerName: 'Количество SKU в проекте'
  }, {
    field: 'article',
    headerName: 'Артикул',
    width: 100,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row?.article} />;
    },
    valueGetter: (params) => {
      if (!params) {
        return [];
      }
      let { row } = params;
      return row?.article.join(',');
    }
  }, {
    field: 'categories',
    headerName: 'Категорий',
    width: 200,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row?.categories} />;
    },
    valueGetter: (params) => {
      if (!params) {
        return [];
      }
      let { row } = params;
      return row?.categories.join(',');
    }
  }, {
    field: 'subcategory',
    headerName: 'Субкатегория',
    width: 200,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row?.subcategory} />;
    },
    valueGetter: (params) => {
      if (!params) {
        return [];
      }
      let { row } = params;
      return row?.subcategory.join(',');
    }
  }, {
    field: 'brands',
    headerName: 'Бренды',
    width: 200,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row?.brands} />;
    },
    valueGetter: (params) => {
      if (!params) {
        return [];
      }
      let { row } = params;
      return row?.brands.join(',');
    }
  }, {
    field: 'barcode',
    headerName: 'Штрихкод',
    width: 200,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row?.barcode} />;
    },
    valueGetter: (params) => {
      if (!params) {
        return [];
      }
      let { row } = params;
      return row?.barcode.join(',');
    }
  }, {
    field: 'countries',
    headerName: 'Страны Дистрибуции',
    width: 300
  }, {
    field: 'created',
    headerName: 'Создан',
    width: 300
  }, {
    field: 'trade_price',
    headerName: 'Оптовая цена',
  }, {
    field: 'retail_price',
    headerName: 'Розничная цена',
  }, {
    field: 'distribution',
    headerName: 'Страны дистрибуции',
    width: 200,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row?.countries} />;
    },
    valueGetter: (params) => {
      if (!params) {
        return [];
      }
      let { row } = params;
      return row?.countries.join(',');
    }
  }, {
    field: 'channels',
    headerName: 'Каналы дистрибуции',
    width: 200,
    renderCell: (params) => {
      let { row } = params;
      return <Chips data={row?.channels} />;
    },
    valueGetter: (params) => {
      if (!params) {
        return [];
      }
      let { row } = params;
      return row?.channels.join(',');
    }
  }, {
    field: 'products_date',
    headerName: 'Дата производства',
    width: 300
  }, {
    field: 'sales_date',
    headerName: 'Дата начала продаж',
    width: 300
  }, {
    field: 'approval_date',
    headerName: 'Дата утверждение проекта',
  }];

  const handleEditClick = (id) => {
    let _form = rows.find(v => {
      return v._id == id;
    });
    if (!_form) {
      alert('У вас нет доступа к данному договору');
      return;
    }
    setForm(_form);
    setFormAlert("");
    setFormError({});
    setEdit(true);
    setOpen(true);
  }

  const handleClickOpen = () => {
    setFormAlert("");
    setFormError({});
    setOpen(true);
  };

  const loadSku = useCallback((aggregate) => _protected.load(auth.token, 'sku', aggregate), []);

  useEffect(() => {
    let active = true;

    const aggregate = [];

    if (window.BX24) {
      let info = window.BX24.placement.info();
      switch (info.placement) {
        case "TASK_VIEW_TAB":
          aggregate.splice(0, 0, {
            '$match': {
              'taskId': info.options.taskId
            }
          });
          setCurrentTaskId(parseInt(info.options.taskId));
          break;
      }
      window.BX24.callMethod('user.current', {}, (res) => {
        if (res.data()) {
          let user = res.data();
          setCurrentUser(user);
        }
      });
    }

    if (loading) {
      loadSku(aggregate).then(v => {
        if (active && v.status === 'ok') {
          setRows(v.result);
          setLoading(false);
        }
      });
    }
    return () => {
      active = false;
    }
  }, [loading]);

  useEffect(() => {
    if (currentTask != 0) {
      const getTask = async () => {
        let task = await b24Call('tasks.task.get', {
          taskId: currentTask
        });
        let groupId = task.task.groupId;
        let result = await b24Call('crm.item.list', {
          "entityTypeId": 1032,
          "filter": {
            "0": { "ufCrm7_1730210846952": `%/${groupId}/%` }
          }
        });
        setLinked(result.items);
      }
      getTask();
    }
  }, [currentTask]);

  const handleClose = () => {
    setForm({});
    setEdit(false);
    setOpen(false);
  };

  const handleClickDelete = () => {
    setDelete(true);
  }

  const handleDelete = async () => {
    for (let i = 0; i < selected.length; i++) {
      await _protected.delete(auth.token, 'international', selected[i]);
    }
    setLoading(true);
    setDelete(false);
  }

  const handleSave = async (args) => {

  }

  const send = async (args) => {
    let data = {
      title_project: form.title_project,
      contract: form.contract,
      contract_date: form.contract_date,
      end_contract: form.end_contract,
      taskId: currentTask ?? 0,
      countSku: form.countSku,
      comment: form.comment,
      categories: form.categories,
      subcategory: form.subcategory,
      brands: form.brands,
      barcode: form.barcode,
      countries: form.countries,
      article: form.article,
      created: form.created ?? new Date().toISOString().split('T')[0],
      trade_price: form.trade_price,
      retail_price: form.retail_price,
      distribution: form.distribution,
      channels: form.channels,
      products_date: form.products_date,
      sales_date: form.sales_date,
      approval_date: form.approval_date,
      trade_price: form.trade_price,
      _stage: args?._stage ?? form._stage ?? "Новый"
    }
    if (form._id) {
      let _id = form._id;
      delete form._id;
      _protected.update(auth.token, 'sku', {
        _id: _id
      }, {
        $set: form
      }).then(result => {
        setLoading(true);
        setForm({});
        handleClose();
      });
    } else {
      _protected.add(auth.token, 'sku', data).then(v => {
        if (v.status === 'ok') {
          setLoading(true);
          setForm({});
          handleClose();
        }
      });
    }
  }

  const b24Call = (method, data) => {
    console.log(`b24 call ${method}`);
    return new Promise((resolve, reject) => {
      fetch(`https://bitrix.riki.team/rest/552/9tfrqzd7bo3ddsz8/${method}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(response => response.json())
        .catch(reason => reject(reason))
        .then(async (result) => {
          resolve(result.result);
        })
    })
  }

  return [
    <Box component="div" sx={{ margin: '10px 5px' }}>
      <Button size='small' onClick={handleClickOpen} variant="contained">Добавить</Button>
      {selected.length > 0 && auth.bxMode != true && <Button size='small' onClick={handleClickDelete} variant="contained" color="error" sx={{ ml: 1 }}>Удалить</Button>}
    </Box>,
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        {useMemo(() => <DataGridPro
          getRowId={(row) => row._id}
          getRowHeight={() => 'auto'}
          showCellRightBorder
          //getDetailPanelHeight={({}) => 200}
          //getDetailPanelContent={getDetailPanelContent}
          rows={rows}
          columns={columns}
          onSelectionModelChange={(selection) => setSelected(selection)}
          selectionModel={selected}
          slots={{
            toolbar: GridToolbar
          }}
          pagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          initialState={{
            pagination: {
              pageSize: 25
            }
          }}
          checkbosizeelection
          disableSelectionOnClick
        />, [rows])}
      </div>
    </div>,
    <Dialog open={open} aria-labelledby="responsive-dialog-title" maxWidth="md" fullWidth sx={{ zIndex: 999 }}>
      <DialogTitle id="responsive-dialog-title">
        {!edit && "Добавление нового элемента."}
        {edit && "Редактирование элемента."}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2} paddingTop={1}>
          <Grid items size={6}>
            <Autocomplete
              disablePortal
              freeSolo
              value={form.contract ?? ""}
              options={linked.map(m => ({ label: m.title }))}
              renderInput={(params) => <TextField {...params} label="Номер Договора" />}
              onChange={async (e, v) => {
                if (v) {
                  let selected = linked.find(f => f.title === v.label ?? v);
                  if (selected) {
                    let set = {};
                    if (selected['ufCrm7_1731414380150']) {
                      set['end_contract'] = new Date(selected['ufCrm7_1731414380150']).toISOString().split('T')[0];
                    }

                    if (selected['companyId']) {
                      let company = await b24Call('crm.company.get', { id: selected['companyId'] });
                      if (company) {
                        set['licensor'] = company['TITLE'];
                      }
                    }

                    if (selected['ufCrm7_1730205482']) {
                      let _setValue = [];
                      for (let s of selected['ufCrm7_1730205482']) {
                        let list = await b24Call('lists.element.get', {
                          'IBLOCK_TYPE_ID': 'lists',
                          'IBLOCK_ID': "82",
                          'ELEMENT_ID': s
                        });
                        _setValue.push(list[0]['NAME']);
                      }
                      set['categories'] = _setValue;
                    }

                    if (selected['ufCrm7_1730206854250']) {
                      let _setValue = [];
                      for (let s of selected['ufCrm7_1730206854250']) {
                        _setValue.push(s);
                      }
                      set['subcategory'] = _setValue;
                    }

                    if (selected['ufCrm7_1730205563']) {
                      let _setValue = [];
                      for (let s of selected['ufCrm7_1730205563']) {
                        let list = await b24Call('lists.element.get', {
                          'IBLOCK_TYPE_ID': 'lists',
                          'IBLOCK_ID': "83",
                          'ELEMENT_ID': s
                        });
                        _setValue.push(list[0]['NAME']);
                      }
                      set['brands'] = _setValue;
                    }

                    if (selected['ufCrm7_1730206896215']) {
                      set['countSku'] = selected['ufCrm7_1730206896215'];
                    }

                    if (selected['ufCrm7_1730206916145']) {
                      let _setValue = [];
                      for (let s of selected['ufCrm7_1730206916145']) {
                        _setValue.push(s);
                      }
                      set['article'] = _setValue;
                    }

                    if (selected['ufCrm7_1730206947496']) {
                      let _setValue = [];
                      for (let s of selected['ufCrm7_1730206947496']) {
                        _setValue.push(s);
                      }
                      set['barcode'] = _setValue;
                    }

                    if (selected['ufCrm7_1730205690']) {
                      let _setValue = [];
                      for (let s of selected['ufCrm7_1730205690']) {
                        let list = await b24Call('lists.element.get', {
                          'IBLOCK_TYPE_ID': 'lists',
                          'IBLOCK_ID': "85",
                          'ELEMENT_ID': s
                        });
                        _setValue.push(list[0]['NAME']);
                      }
                      set['countries'] = _setValue;
                    }

                    if (selected['ufCrm7_1730207181']) {
                      let _setValue = [];
                      for (let s of selected['ufCrm7_1730207181']) {
                        let list = await b24Call('lists.element.get', {
                          'IBLOCK_TYPE_ID': 'lists',
                          'IBLOCK_ID': "86",
                          'ELEMENT_ID': s
                        });
                        _setValue.push(list[0]['NAME']);
                      }
                      set['channels'] = _setValue;
                    }

                    if (selected['ufCrm7_1730207261006']) {
                      set['products_date'] = new Date(selected['ufCrm7_1730207261006']).toISOString().split('T')[0];
                    }

                    console.log(selected);
                    console.log(set);
                    console.log({ ...form, ...set });
                    setForm({ ...form, ...set, contract: v.label ?? v });
                  } else {
                    setForm({ ...form, contract: v.label ?? v });
                  }
                }
              }}
            />
          </Grid>
          <Grid item size={6}>
            <TextField
              id="licensor"
              label="Лицензиат"
              type="text"
              value={form.licensor ?? ""}
              onChange={(e) => {
                setForm({ ...form, licensor: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid items size={6}>
            <TextField
              id="contract_date"
              label="Дата договора"
              type="date"
              value={form.contract_date ?? ""}
              onChange={(e) => {
                console.log(e.target.value);
                setForm({ ...form, contract_date: e.target.value });
              }}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true
              }} />
          </Grid>
          <Grid items size={6}>
            <TextField
              id="end_contract"
              label="Срок договора"
              type="date"
              value={form.end_contract ?? ""}
              onChange={(e) => {
                setForm({ ...form, end_contract: e.target.value });
              }}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true
              }} />
          </Grid>
          <Grid items size={6}>
            <Autocomplete
              multiple
              limitTags={2}
              freeSolo
              options={form.categories ?? []}
              value={form.categories ?? []}
              isOptionEqualToValue={(option, value) => option.value === value}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Категорий" />
              )}
              onChange={async (e, v) => {
                setForm({ ...form, categories: v });
              }} />
          </Grid>
          <Grid item size={6}>
            <Autocomplete
              multiple
              limitTags={2}
              freeSolo
              options={form.subcategory ?? []}
              value={form.subcategory ?? []}
              isOptionEqualToValue={(option, value) => option.value === value}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Субкатегория" />
              )}
              onChange={async (e, v) => {
                setForm({ ...form, subcategory: v });
              }} />
          </Grid>
          <Grid item size={6}>
            <Autocomplete
              multiple
              limitTags={2}
              freeSolo
              options={form.brands ?? []}
              value={form.brands ?? []}
              isOptionEqualToValue={(option, value) => option.value === value}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Бренды" />
              )}
              onChange={async (e, v) => {
                setForm({ ...form, brands: v });
              }} />
          </Grid>
          <Grid item size={6}>
            <TextField
              id="title_project"
              label="Наименование проекта"
              type="text"
              value={form.title_project ?? ""}
              onChange={(e) => {
                setForm({ ...form, title_project: e.target.value });
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item size={12}>
            <TextField
              id="comment"
              label="Комментарий"
              type="text"
              value={form.comment ?? ""}
              onChange={(e) => {
                setForm({ ...form, comment: e.target.value });
              }}
              multiline
              rows={4}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item size={6}>
            <TextField
              id="trade_price"
              label="Оптовая цена"
              type="number"
              value={form.trade_price ?? ""}
              onChange={(e) => {
                setForm({ ...form, trade_price: e.target.value })
              }}
              sx={{ width: '100%' }}
              slotProps={{
                input: {
                  startAdornment: <InputAdornment position="start">₽</InputAdornment>,
                }
              }}
            />
          </Grid>
          <Grid item size={6}>
            <TextField
              id="retail_price"
              label="Розничная цена"
              type="number"
              value={form.retail_price ?? ""}
              onChange={(e) => {
                setForm({ ...form, retail_price: e.target.value })
              }}
              sx={{ width: '100%' }}
              slotProps={{
                input: {
                  startAdornment: <InputAdornment position="start">₽</InputAdornment>,
                }
              }}
            />
          </Grid>
          <Grid item size={6}>
            <TextField
              id="sales_date"
              label="Дата начала продаж"
              type="date"
              value={form.sales_date ?? ""}
              onChange={(e) => {
                setForm({ ...form, sales_date: e.target.value });
              }}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item size={6}>
            <TextField
              id="approval_date"
              label="Дата утверждение проекта"
              type="date"
              value={form.approval_date ?? ""}
              onChange={(e) => {
                setForm({ ...form, approval_date: e.target.value });
              }}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item size={6}>
            <TextField
              id="countSku"
              label="SKU в проекте"
              type="number"
              value={form.countSku ?? ""}
              onChange={(e) => {
                setForm({ ...form, countSku: e.target.value })
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item size={6}>
            <Autocomplete
              multiple
              limitTags={2}
              freeSolo
              options={form.article ?? []}
              value={form.article ?? []}
              isOptionEqualToValue={(option, value) => option.value === value}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Артикулы" />
              )}
              onChange={async (e, v) => {
                setForm({ ...form, article: v });
              }} />
          </Grid>
          <Grid item size={6}>
            <Autocomplete
              multiple
              limitTags={2}
              freeSolo
              options={form.barcode ?? []}
              value={form.barcode ?? []}
              isOptionEqualToValue={(option, value) => option.value === value}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Штрихкод" />
              )}
              onChange={async (e, v) => {
                setForm({ ...form, barcode: v });
              }} />
          </Grid>
          <Grid item size={6}>
            <Autocomplete
              multiple
              limitTags={2}
              freeSolo
              options={form.countries ?? []}
              value={form.countries ?? []}
              isOptionEqualToValue={(option, value) => option.value === value}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Страны дистрибуции" />
              )}
              onChange={async (e, v) => {
                setForm({ ...form, countries: v });
              }} />
          </Grid>
          <Grid item size={6}>
            <Autocomplete
              multiple
              limitTags={2}
              freeSolo
              options={form.channels ?? []}
              value={form.channels ?? []}
              isOptionEqualToValue={(option, value) => option.value === value}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Каналы дистрибуции" />
              )}
              onChange={async (e, v) => {
                setForm({ ...form, channels: v });
              }} />
          </Grid>
          <Grid items size={6}>
            <TextField
              id="products_date"
              label="Дата производства"
              type="date"
              value={form.products_date ?? ""}
              onChange={(e) => {
                console.log(e.target.value);
                setForm({ ...form, products_date: e.target.value });
              }}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true
              }} />
          </Grid>
          <Grid items size={6}>
            <TextField
              id="sales_date"
              label="Дата начала продаж"
              type="date"
              value={form.sales_date ?? ""}
              onChange={(e) => {
                console.log(e.target.value);
                setForm({ ...form, sales_date: e.target.value });
              }}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true
              }} />
          </Grid>
          <Grid items size={6}>
            <TextField
              id="approval_date"
              label="Дата утверждения проекта"
              type="date"
              value={form.approval_date ?? ""}
              onChange={(e) => {
                console.log(e.target.value);
                setForm({ ...form, approval_date: e.target.value });
              }}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true
              }} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div>
          <Button
            variant="outlined"
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            Отправить
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={ () => setAnchorEl(null) }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={() => send({ _stage: 'Отправлен', dest: 'pub' })}>Согласование Паблишинг</MenuItem>
            <MenuItem onClick={() => send({ _stage: 'Отправлен', dest: 'design' })}>Согласование Дизайн</MenuItem>
          </Menu>
        </div>
        <Button onClick={() => send()} autoFocus>
          Сохранить
        </Button>
        <Button onClick={handleClose} autoFocus>
          Отменить
        </Button>
      </DialogActions>
    </Dialog>,
    <Dialog open={deleting} aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{"Удаление объектов."}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Подтвердите удаление.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDelete(false)}>Нет</Button>
        <Button onClick={() => handleDelete()}>Да</Button>
      </DialogActions>
    </Dialog>
  ];
}

export default RoomSku;