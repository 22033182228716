import { createContext, useContext, useState } from "react";
import books from "../shared/books";
import { useAuth } from "./AuthProvider";

const BookContext = createContext();

function useBook() {
  return useContext(BookContext);
};

function BookProvider({ children }) {
  let auth = useAuth();

  const [book, setBook] = useState({
    dataBook: {},
    get: (name) => {
      return [];
      if (book.dataBook[name] === undefined) {
        if (name !== 'users') {
          books.getBook(auth.token, name).then(value => {
            let _sate = {...book};
            _sate.dataBook[name] = value.result;
            setBook(_sate);
          });
        } else {
          books.getUsers(auth.token).then(value => {
            let _sate = {...book };
            _sate.dataBook[name] = value.result;
            setBook(_sate);
          })
        }
      }
      return book.dataBook[name] ?? [];
    }
  });

  const value = {
    book
  };

  console.log('render BookProvider');

  return (
    <BookContext.Provider value={value}>
      {children}
    </BookContext.Provider>
  );
};

export { BookProvider, useBook };